import React, { useEffect, useMemo, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Add, KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import DataTable from "react-data-table-component";
import useStyles from "./styles";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useUserState } from "../../context/UserContext";
import { fnbFoodImagesTableColumnConfig } from "./data-table/fnb-food-images-table-column-config";
import FoodAddon from "./components/FoodAddOn/FoodAddOn";
import * as path from "path";
import { fnbFoodSchedulesTableColumnConfig } from "./data-table/fnb-food-schedules-table-column-config";
import { DAY_OF_THE_WEEK } from "../../data/constants";
import moment from "moment";
import { fnbFoodSpecialPriceTableColumnConfig } from "./data-table/fnb-food-special-price-table-column-config";
import { getPureDate } from "../../helper/get-pure-date";
import DateTimeController from "../../components/DateTimeController";

const FnbFoodCard = (props) => {
  const classes = useStyles();
  const { userInfo } = useUserState();

  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  const { foodId } = useParams(); // url param => foodId when editing

  // handling form when adding / editing
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    control,
    setError,
    getValues,
    setValue,
    watch,
  } = useForm({ mode: "all" });

  // un-assigned sub-con list
  const [message, setMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [currentFood, setCurrentFood] = useState(null); // when edit
  const [foodAddOnList, setFoodAddOnList] = useState([]); // menu item list
  const [rowsInvalid, setRowsInvalid] = useState(false);
  const [lstDeletedImages, setLstDeletedImages] = useState([]);
  let selectedFoodImage = 0;
  const [activeFrom, setActiveFrom] = useState(null);
  const [activeTo, setActiveTo] = useState(null);

  const [fnbStores, setFnbStores] = useState([]);
  const [fnbCategories, setFnbCategories] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [duplicated, setDuplicated] = useState(false);
  const [enabledSwitchValue, setEnabledSwitchValue] = useState(true); // enable switch => default true
  const [noStockSwitchValue, setNoStockSwitchValue] = useState(false);
  const [promoteSwitchValue, setPromoteSwitchValue] = useState(false);
  const [chefRecommendSwitchValue, setChefRecommendSwitchValue] =
    useState(false);
  const [vegetarianSwitchValue, setVegetarianSwitchValue] = useState(false);
  const [healthierChoiceSwitchValue, setHealthierChoiceSwitchValue] =
    useState(false);
  const [popularSwitchValue, setPopularSwitchValue] = useState(false);
  const [images, setImages] = useState([]);
  const [orgImageFolderName, setOrgImageFolderName] = useState(null);
  const [errMessage, setErrMessage] = useState("");
  const [incompleteAddOn, setIncompleteAddOn] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const dayOfWeekList = Object.values(DAY_OF_THE_WEEK);
  const [sortedSchedule, setSortedSchedule] = useState(false);
  const [specialPrices, setSpecialPrices] = useState([]);
  const [storeFood, setStoreFood] = useState([]);
  const [adminUseSwitchValue, setAdminUseSwitchValue] = useState(false);

  const isPresetMeal = watch("isPresetMeal", false);


  const isComponentEditDisabled = useMemo(
    () =>
      !!(
        currentFood &&
        foodId &&
        !auth.checkModulePrivilege(
          PrivilegeModules.fnb_food,
          PrivilegeActions.edit,
        )
      ),
    [auth, currentFood, foodId],
  );

  useEffect(() => {
    document.title = "New - F&B Dish Card";
    loadPrerequisites();

    // redirect to default page if not authorized
    if (
      foodId &&
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.fnb_food,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }

    if (!foodId) {
      setValue("seqNo", 0);
    }
  }, []);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: navigateToList,
      });
      setMessage("");
    }
  }, [message, responseData]);

  useEffect(() => {
    if (errMessage) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });

      setErrMessage("");
    }
  }, [errMessage]);

  useEffect(() => {
    if (fnbStores && currentFood) {
      setSelectedStore(
        fnbStores
          .filter((outlet) => outlet.storeId === currentFood.store.storeId)
          .pop(),
      );
    }
  }, [currentFood, fnbStores]);

  useEffect(() => {
    if (!sortedSchedule) {
      sortSchedules();
    }
  }, [schedules]);

  useEffect(() => {
    if (fnbCategories && currentFood) {
      setSelectedCategory(
        fnbCategories
          .filter(
            (category) => category.entityId === currentFood.category.entityId,
          )
          .pop(),
      );
    }
  }, [currentFood, fnbCategories]);

  useEffect(() => {
    if (!selectedStore) {
      return;
    }

    setValue("storeId", selectedStore.code);
    loadCategoryList(selectedStore.storeId);
    loadStoreItems(selectedStore.storeId);
  }, [selectedStore]);

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }

    setValue("categoryEntityId", selectedCategory.entityId);
  }, [selectedCategory]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  useEffect(() => {
    if (warningMessage) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: warningMessage,
      });
      setWarningMessage("");
    }
  }, [warningMessage, responseData]);

  // get current matrix in edit view
  useEffect(() => {
    if (!isLoading && !responseData && foodId) {
      sendRequest(`/v1/fnb-food/get-food-by-id/` + foodId, "GET");
    }
  }, [foodId]);

  useEffect(() => {
    if (!foodAddOnList) {
      return;
    }

    let foundIncompleteAddOn = false;
    for (const addOn of foodAddOnList) {
      if (
        !addOn.name ||
        addOn.options.length == 0 ||
        addOn.options.findIndex(
          (option) => option.name === "" || option.price === "",
        ) > -1
      ) {
        foundIncompleteAddOn = true;
        break;
      }

      if (foundIncompleteAddOn) {
        break;
      }
    }

    setIncompleteAddOn(foundIncompleteAddOn);
  }, [foodAddOnList]);

  useEffect(() => {
    const invalid =
      incompleteAddOn ||
      images.findIndex((r) => !r.url) > -1 ||
      !selectedStore ||
      !selectedCategory ||
      schedules.findIndex(
        (r) => r.dayOfWeek < 0 || r.fromTimeInNum < 0 || r.toTimeInNum < 0,
      ) > -1 ||
      specialPrices.findIndex(
        (r) => r.price <= 0 || !r.activeFrom || !r.activeTo,
      ) > -1;
    setRowsInvalid(invalid);
  }, [
    images,
    selectedStore,
    selectedCategory,
    incompleteAddOn,
    schedules,
    specialPrices,
  ]);

  // set form values when edit view
  useEffect(() => {
    if (foodId && responseData?.food) {
      const food = responseData?.food;
      document.title = `${food.sku} - F&B Dish Card`;
      setCurrentFood(food);
      setValue("storeId", food.store.storeId || "");
      setValue("categoryEntityId", food.category.entityId || "");
      setValue("sku", food.sku || "");
      setValue("name", food.name || "");
      setValue("nameInChinese", food.nameInChinese || "");
      setValue("description", food.description || "");
      setValue("descriptionInChinese", food.descriptionInChinese || "");
      setValue("price", food.price || "");
      setValue("takeawayFee", food.takeawayFee || "");
      setValue("activeFrom", food.activeFrom || "");
      setValue("activeTo", food.activeTo || "");
      setValue("status", food.status);
      setValue("seqNo", food.seqNo);
      setValue("noStock", !food.inStock);
      setValue("promote", food.promote);
      setValue("chefRecommend", food.chefRecommend);
      setValue("vegetarian", food.vegetarian);
      setValue("healthierChoice", food.healthierChoice);
      setValue("popular", food.popular);
      setValue("isPresetMeal", food.isPresetMeal);
      setEnabledSwitchValue(food.status); // enable switch
      setNoStockSwitchValue(!food.inStock);
      setPromoteSwitchValue(food.promote);
      setChefRecommendSwitchValue(food.chefRecommend);
      setVegetarianSwitchValue(food.vegetarian);
      setHealthierChoiceSwitchValue(food.healthierChoice);
      setPopularSwitchValue(food.popular);
      setActiveFrom(food.activeFrom);
      setActiveTo(food.activeTo);
      setImages(food.images);
      setFoodAddOnList(food.availableAddOns);
      setSortedSchedule(false);
      let schedules = food.schedules.map((obj) => {
        return { ...obj, dayOfWeekDesc: dayOfWeekList[obj.dayOfWeek] };
      });
      setSchedules(schedules);
      setSpecialPrices(food.specialPrices);
      setAdminUseSwitchValue(food.adminUse);
    }
  }, [responseData]);

  const navigateToList = () => {
    if (!foodId) {
      window.location.href = "#/app/fnb-food/list";
    }
  };

  const loadStoreItems = (storeId) => {
    sendRequest(`/v1/fnb-food/get-store-food/${storeId}`, "GET", {}).then(
      (response) => {
        setStoreFood(response.data.storeFood);
      },
    );
  };

  const checkIfExistingFood = (sku) => {
    sendRequest(
      `/v1/fnb-food/check-if-existing-food/${selectedStore.storeId}/${sku}`,
      "GET",
      {},
    ).then((response) => {
      if (response.data.existingFood) {
        setErrMessage(
          `Item ${sku} is already existed in ${selectedStore.name}.`,
        );
        setValue("sku", "");
      } else {
        getItemFromNav(sku);
      }
    });
  };

  const getItemFromNav = (sku) => {
    sendRequest(
      `/v1/fnb-food/get-fnb-item-from-nav/${selectedStore.storeCode}/${sku}`,
      "GET",
      {},
    ).then((response) => {
      if (response.data.invalidItem) {
        setErrMessage(`Item ${sku} doesn't exist in Navision.`);
        clearItemData();

        return;
      }

      setValue("sku", sku);
      setValue("name", camelCase(response.data.food[0].description));
      setValue("price", response.data.food[0].price);
    });
  };

  const loadPrerequisites = () => {
    sendRequest(`/v1/fnb-food/get-prerequisites`, "GET", {}).then(
      (response) => {
        let prerequisites = response.data.prerequisites;
        setFnbStores(prerequisites.fnbStores);
      },
    );
  };

  const loadCategoryList = (storeId) => {
    setFnbCategories(
      fnbStores.filter((c) => {
        return c.storeId == storeId;
      })[0].categories,
    );
  };

  const handleSubmitForm = async (
    data,
    e,
    preview = false,
    duplicate = false,
  ) => {
    e.preventDefault();

    if (!selectedStore) {
      setErrMessage("Store cannot be empty.");
      return;
    }

    if (!selectedCategory) {
      setErrMessage("Category cannot be empty.");
      return;
    }

    let invalidateSchedules = !validateSchedules(schedules);
    let invalidateSpecialPrices = !validateSpecialPrices(specialPrices);
    if (invalidateSchedules || invalidateSpecialPrices) {
      setErrMessage("Invalid data in schedule/special prices.");
      return;
    }

    //duplicating/uploading/deleting food images
    let result1 = null;
    let folderName = `${selectedStore.storeCode}-${data.sku}`;

    if (duplicated && !currentFood) {
      let lstDuplicateImageUrls = images
        .filter((e) => {
          return e.isNewImage == true && e.isDuplicated == true;
        })
        .map((item) => item.url);
      result1 = await sendRequest(
        `/v1/fnb-food/duplicate-images-in-s3/${folderName}/${orgImageFolderName}`,
        "POST",
        lstDuplicateImageUrls,
      );

      if (result1?.status === 201) {
        images
          .filter((mi) => {
            return mi.isNewImage == true && mi.isDuplicated == true;
          })
          .forEach((m, index) => {
            m.url = result1.data.urlArray[index].url;
          });
      } else {
        return;
      }
    }

    let lstImageUrls = [];
    let formData = new FormData();
    for (var i = 0; i < images.length; i++) {
      if (images[i].isNewImage == true && images[i].isDuplicated == false) {
        formData.append("file", images[i].url);
      } else {
        lstImageUrls.push(images[i].url);
      }
    }

    let deletedImageNames =
      lstDeletedImages.toString() == ""
        ? undefined
        : lstDeletedImages.toString();
    result1 = await sendRequest(
      `/v1/fnb-food/upload-image-onto-s3/${folderName}/${deletedImageNames}`,
      "POST",
      formData,
    );

    if (result1?.status === 201) {
      images
        .filter((mi) => {
          return mi.isNewImage == true && mi.isDuplicated == false;
        })
        .forEach((m, index) => {
          m.url = result1.data.urlArray[index].url;
        });
    } else {
      return;
    }
    //

    const food = {
      ...data,
      entityId: foodId,
      store: selectedStore,
      category: selectedCategory,
      inStock: !data.noStock,
      price: Number(data.price),
      takeawayFee: Number(data.takeawayFee === "" ? 0 : data.takeawayFee),
      activeFrom: data.activeFrom === "" ? undefined : data.activeFrom,
      activeTo: data.activeTo === "" ? undefined : data.activeTo,
      images: images.map((img) => ({
        url: img.url,
        label: img.label,
        default: img.default,
      })),
      availableAddOns: foodAddOnList,
      schedules: schedules,
      createdBy:
        foodId && currentFood && currentFood.entityId
          ? data.createdBy
          : userInfo._id,
      updatedBy:
        foodId && currentFood && currentFood.entityId
          ? userInfo._id
          : undefined,
    };

    let savedFood = undefined,
      message = "";
    if (currentFood && foodId) {
      // when edit
      food.entityId = currentFood.entityId;
      const result = await sendRequest(`/v1/fnb-food/${foodId}`, "PUT", food);
      if (result?.status === 200 && result?.data?.food) {
        message = `F&B dish ${result.data.food.sku} has been updated.`;
        savedFood = result?.data?.food;
      }
    } else {
      const result = await sendRequest(`/v1/fnb-food`, "POST", food);
      if (result?.status === 201 && result?.data?.food) {
        message = `New F&B dish ${result.data.food.sku} has been created.`;
        savedFood = result?.data?.food;
      }
    }

    if (duplicate && savedFood) {
      setOrgImageFolderName(`${selectedStore.storeCode}-${data.sku}`);
      duplicateFood();
    } else {
      setMessage(message);
    }
  };

  function onRemoveImage(i) {
    const rList = [...images];

    if (!rList[i].isNewImage && !rList[i].isDuplicated) {
      var deletedFileName = path.basename(rList[i].url);
      setLstDeletedImages((current) => [...current, deletedFileName]);
    } else {
      rList[i].url_Local = "";
    }

    rList[i].url = "";
    setImages(rList);
  }

  // remove resource from list
  function onRemoveRowImage(i) {
    onRemoveImage(i);
    const rows = [...images];
    rows.splice(i, 1);
    setImages([...rows]);
  }

  async function onChangeDefault(i, toggleValue = false) {
    const rList = [...images];

    if (toggleValue) {
      rList.forEach((img) => {
        img.default = false;
      });

      rList[i].default = true;
    } else {
      rList[i].default = false;
    }

    setImages(rList);
  }

  function onSelectImage(i) {
    selectedFoodImage = i;
    document.getElementById("input-file-upload").click();
  }

  function imagePreview(menuItem) {
    window.open(menuItem.isNewImage ? menuItem.url_Local : menuItem.url);
  }

  // reset prev matrix data & copy resources to new matrix
  function duplicateFood() {
    props.history.push("/app/fnb-food/card");
    setDuplicated(true);
    setSortedSchedule(false);
    setCurrentFood(null);
    setValue("sku", "");
    setValue("storeId", "");
    setValue("seqNo", 0);
    setSelectedStore(null);
    setSelectedCategory(null);
    setFnbCategories([]);

    const rList = [...images];
    rList.forEach((e) => {
      e.url_Local = e.url;
      e.isNewImage = true;
      e.isDuplicated = true;
    });
    setImages(rList);

    const rScheduleList = [...schedules];
    for (let i = 0; i < rScheduleList.length; i++) {
      delete rScheduleList[i].entityId;
      delete rScheduleList[i].createdAt;
    }
    setSchedules(rScheduleList);

    const rSpecialPriceList = [...specialPrices];
    for (let i = 0; i < rSpecialPriceList.length; i++) {
      delete rSpecialPriceList[i].entityId;
      delete rSpecialPriceList[i].createdAt;
      delete rSpecialPriceList[i].updatedAt;
    }
    setSpecialPrices(rSpecialPriceList);

    const rFoodAddOnList = [...foodAddOnList];
    for (let i = 0; i < rFoodAddOnList.length; i++) {
      delete rFoodAddOnList[i].entityId;
      delete rFoodAddOnList[i].createdAt;
      delete rFoodAddOnList[i].updatedAt;

      for (let j = 0; j < rFoodAddOnList[i].options.length; j++) {
        delete rFoodAddOnList[i].options[j].entityId;
        delete rFoodAddOnList[i].options[j].createdAt;
        delete rFoodAddOnList[i].options[j].updatedAt;
      }
    }
    setFoodAddOnList(rFoodAddOnList);
  }

  function createNewRowForImage() {
    setImages([
      ...images,
      {
        url: "",
        label: "",
        product_id: "",
        default: false,
        isNewImage: true,
        isDuplicated: false,
      },
    ]);
  }

  function createNewRowForSchedule() {
    setSchedules([
      ...schedules,
      {
        dayOfWeek: -1,
        dayOfWeekDesc: "",
        fromTime: null,
        toTime: null,
        fromTimeInNum: -1,
        toTimeInNum: -1,
      },
    ]);
  }

  function createNewRowForSpecialPrice() {
    setSpecialPrices([
      ...specialPrices,
      {
        price: 0,
        activeFrom: null,
        activeTo: null,
      },
    ]);
  }

  function setFoodImage(e) {
    for (var j = 0; j < e.target.files.length; j++) {
      const rList = [...images];
      rList[selectedFoodImage].url = e.target.files[j];
      rList[selectedFoodImage].label = e.target.files[j].name;
      rList[selectedFoodImage].url_Local = URL.createObjectURL(
        e.target.files[j],
      );
      rList[selectedFoodImage].isNewImage = true;
      rList[selectedFoodImage].isDuplicated = false;
      setImages(rList);
    }
  }

  const onChangeCategory = (newValue) => {
    setValue("categoryId", newValue.entityId);
    setSelectedCategory(newValue);
  };

  const onChangeStore = (newValue) => {
    setValue("storeId", newValue.storeId);
    setSelectedStore(newValue);

    setValue("categoryId", "");
    setSelectedCategory(null);
    clearItemData();
  };

  const clearItemData = () => {
    setValue("sku", "");
    setValue("name", "");
    setValue("nameInChinese", "");
    setValue("description", "");
    setValue("descriptionInChinese", "");
    setValue("price", "");
  };

  const onChangeDate_ActiveFrom = (date) => {
    setActiveFrom(date);
  };

  const onChangeDate_ActiveTo = (date) => {
    setActiveTo(date);
  };

  const handleBlurOnSKU = (sku) => {
    if (!!selectedStore && !!sku) {
      checkIfExistingFood(sku);
    }
  };

  function onRemoveScheduleRow(i) {
    const rows = [...schedules];
    rows.splice(i, 1);
    setSchedules([...rows]);
  }

  function onRemoveSpecialPriceRow(i) {
    const rows = [...specialPrices];
    rows.splice(i, 1);
    setSpecialPrices([...rows]);
  }

  function getNumOfTime(dateTime) {
    const myDateTime = moment(dateTime, "HH:mm:ss");
    const hour = myDateTime.hour();
    const minute = myDateTime.minute();
    return hour + minute / 60;
  }

  function onChangeSchedule(type, index, value) {
    const rList = [...schedules];
    rList[index][type] = value;

    if (type == "fromTime") {
      rList[index].fromTimeInNum = getNumOfTime(value);
    } else if (type == "toTime") {
      rList[index].toTimeInNum = getNumOfTime(value);
    } else if (type == "dayOfWeekDesc") {
      rList[index].dayOfWeek = dayOfWeekList.indexOf(value);
    }

    setSchedules(rList);
  }

  function onChangeSpecialPrice(type, index, value) {
    const rList = [...specialPrices];
    rList[index][type] = value;
    setSpecialPrices(rList);
  }

  function checkTimeOverlap(fromTime1, toTime1, fromTime2, toTime2) {
    return fromTime1 <= toTime2 && fromTime2 <= toTime1;
  }

  function validateSchedules(scheduleArray) {
    let foundInvalid = false;

    scheduleArray.forEach((s) => {
      s.invalidData = false;
    });

    for (let i = 0; i < scheduleArray.length; i++) {
      const currentSchedule = scheduleArray[i];

      if (currentSchedule.invalidData) {
        continue;
      }

      if (currentSchedule.fromTimeInNum >= currentSchedule.toTimeInNum) {
        foundInvalid = true;
        scheduleArray[i].invalidData = true;
      } else {
        for (let j = i + 1; j < scheduleArray.length; j++) {
          const otherSchedule = scheduleArray[j];

          if (
            currentSchedule.dayOfWeekDesc === otherSchedule.dayOfWeekDesc &&
            checkTimeOverlap(
              currentSchedule.fromTimeInNum,
              currentSchedule.toTimeInNum,
              otherSchedule.fromTimeInNum,
              otherSchedule.toTimeInNum,
            )
          ) {
            foundInvalid = true;
            scheduleArray[i].invalidData = true;
            scheduleArray[j].invalidData = true;
          }
        }
      }
    }

    setSchedules(scheduleArray);
    return !foundInvalid;
  }

  function validateSpecialPrices(specialPriceArray) {
    let foundInvalid = false;

    specialPriceArray.forEach((s) => {
      s.invalidData = false;
    });

    for (let i = 0; i < specialPriceArray.length; i++) {
      const currentObject = specialPriceArray[i];
      const currentActiveFrom = new Date(currentObject.activeFrom).getTime();
      const currentActiveTo = new Date(currentObject.activeTo).getTime();

      if (currentObject.invalidData) {
        continue;
      }

      if (currentActiveFrom > currentActiveTo) {
        specialPriceArray[i].invalidData = true;
        foundInvalid = true;

        continue;
      } else {
        specialPriceArray[i].invalidData = false;
      }

      for (let j = i + 1; j < specialPriceArray.length; j++) {
        const nextObject = specialPriceArray[j];
        const nextActiveFrom = new Date(nextObject.activeFrom).getTime();
        const nextActiveTo = new Date(nextObject.activeTo).getTime();

        if (
          (currentActiveFrom <= nextActiveTo &&
            currentActiveTo >= nextActiveFrom) ||
          (nextActiveFrom <= currentActiveTo &&
            nextActiveTo >= currentActiveFrom)
        ) {
          specialPriceArray[i].invalidData = true;
          specialPriceArray[j].invalidData = true;
          foundInvalid = true;
        }
      }
    }

    setSpecialPrices(specialPriceArray);
    return !foundInvalid;
  }

  function sortSchedules() {
    if (
      schedules.filter((e) => {
        return e.dayOfWeek < 0;
      }).length > 0
    ) {
      return;
    }

    const rList = [...schedules];
    rList.sort((a, b) => {
      if (a.dayOfWeek < b.dayOfWeek) return -1;
      if (a.dayOfWeek > b.dayOfWeek) return 1;

      if (a.fromTimeInNum < b.fromTimeInNum) return -1;
      if (a.fromTimeInNum > b.fromTimeInNum) return 1;

      return 0;
    });

    setSortedSchedule(true);
    setSchedules(rList);
  }

  const columnsForSpecialPrice = fnbFoodSpecialPriceTableColumnConfig(
    onRemoveSpecialPriceRow,
    onChangeSpecialPrice,
    getPureDate,
    isComponentEditDisabled,
    control,
  );

  const columnsForSchedule = fnbFoodSchedulesTableColumnConfig(
    dayOfWeekList,
    onRemoveScheduleRow,
    onChangeSchedule,
    isComponentEditDisabled,
  );

  const columnsForFoodImage = fnbFoodImagesTableColumnConfig(
    onRemoveRowImage,
    onSelectImage,
    onChangeDefault,
    imagePreview,
    onRemoveImage,
    isComponentEditDisabled,
  );

  function onAddNewRowForAddOn() {
    setFoodAddOnList([
      ...foodAddOnList,
      {
        isMandatory: false,
        name: "",
        nameInChinese: "",
        selectType: "single",
        options: [],
      },
    ]);
  }

  const onChangeAddOn = (type, index, value) => {
    const rList = [...foodAddOnList];
    rList[index][type] = value;
    setFoodAddOnList(rList);
  };

  const onDeleteAddOn = (index) => {
    const rows = [...foodAddOnList];
    rows.splice(index, 1);
    setFoodAddOnList([...rows]);
  };

  const onAddNewRowForAddOnOption = (addOnIndex) => {
    const rList = [...foodAddOnList];
    rList[addOnIndex].options.push({
      sku: "",
      name: "",
      nameInChinese: "",
      price: 0,
    });
    setFoodAddOnList(rList);
  };

  const onChangeAddOnOption = (type, addOnIndex, optionIndex, value) => {
    if (type == "sku" && !value) {
      return;
    }

    if (type == "price" && !value) {
      value = 0;
    }

    const rList = [...foodAddOnList];

    if (type == "sku") {
      rList[addOnIndex].options[optionIndex].sku = value.sku;
      rList[addOnIndex].options[optionIndex].name = value.name;
      rList[addOnIndex].options[optionIndex].nameInChinese =
        value.nameInChinese;
      rList[addOnIndex].options[optionIndex].price = value.price;
    } else {
      rList[addOnIndex].options[optionIndex][type] = value;
    }

    setFoodAddOnList(rList);
  };

  const onDeleteAddOnOption = (addOnIndex, optionIndex) => {
    const rList = [...foodAddOnList];
    rList[addOnIndex].options.splice(optionIndex, 1);
    setFoodAddOnList(rList);
  };

  const isDuplicatingAddOn = (addOnName) =>
    foodAddOnList.filter(
      (r) =>
        !!r.name && r.name.toUpperCase() === addOnName.trim().toUpperCase(),
    ).length > 1;

  function handleBlurAddOn(i, addOnName) {
    checkDuplicateAddOn(i, addOnName);
  }

  function checkDuplicateAddOn(i, addOnName) {
    let foundDuplicate = false;

    const rList = [...foodAddOnList];

    if (
      foodAddOnList.length > 1 &&
      addOnName.length > 0 &&
      isDuplicatingAddOn(addOnName)
    ) {
      setWarningMessage(`Duplicate add-on ${addOnName} found in the list.`);
      rList[i].name = "";
      foundDuplicate = true;
      setFoodAddOnList(rList);
    }
  }

  const isDuplicatingAddOnOption = (addOnIndex, type, addOnOptionValue) =>
    foodAddOnList[addOnIndex].options.filter(
      (r) =>
        !!r[type] &&
        r[type].toUpperCase() === addOnOptionValue.trim().toUpperCase(),
    ).length > 1;

  function handleBlurAddOnOption(addOnIndex, i, type, addOnOptionValue) {
    checkDuplicateAddOnOption(addOnIndex, i, type, addOnOptionValue);
  }

  function checkDuplicateAddOnOption(addOnIndex, i, type, addOnOptionValue) {
    let foundDuplicate = false;

    const rList = [...foodAddOnList];

    if (
      foodAddOnList[addOnIndex].options.length > 1 &&
      addOnOptionValue.length > 0 &&
      isDuplicatingAddOnOption(addOnIndex, type, addOnOptionValue)
    ) {
      setWarningMessage(
        `Duplicate ${type} ${addOnOptionValue} found in the list.`,
      );
      rList[addOnIndex].options[i].sku = "";
      rList[addOnIndex].options[i].name = "";
      rList[addOnIndex].options[i].nameInChinese = "";
      rList[addOnIndex].options[i].price = "0";
      setFoodAddOnList(rList);
      foundDuplicate = true;
    }
  }

  function camelCase(str) {
    let ans = str.toLowerCase();
    ans = ans.split(" ").reduce((s, c, index) => {
      if (index === 0) {
        return c.charAt(0).toUpperCase() + c.slice(1);
      } else {
        return s + " " + (c.charAt(0).toUpperCase() + c.slice(1));
      }
    }, "");

    return ans;
  }

  return (
    <>
      <div className="form-group">
        <input
          type="file"
          className="form-control"
          id="input-file-upload"
          accept="image/*"
          onChange={setFoodImage}
        />
      </div>
      {isLoading && (
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      {
        <form>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>
                F&B Dish Card
                {currentFood ? ` - ${currentFood.sku}` : ` - New`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <Button onClick={() => props.history.push("/app/fnb-food/list")}>
                Cancel
              </Button>
              {((!currentFood &&
                !foodId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.fnb_food,
                  PrivilegeActions.add,
                )) ||
                (currentFood &&
                  foodId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.fnb_food,
                    PrivilegeActions.edit,
                  ))) && (
                <Button
                  disabled={rowsInvalid}
                  className={"primary"}
                  type={"button"}
                  onClick={handleSubmit(handleSubmitForm)}
                >
                  Save
                </Button>
              )}
              {((!currentFood &&
                !foodId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.fnb_food,
                  PrivilegeActions.add,
                )) ||
                (currentFood &&
                  foodId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.fnb_food,
                    PrivilegeActions.edit,
                  ))) && (
                <Button
                  disabled={rowsInvalid}
                  className={"primary"}
                  type={"button"}
                  onClick={handleSubmit((data, e) =>
                    handleSubmitForm(data, e, false, true),
                  )}
                >
                  Save/Duplicate
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>General</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Autocomplete
                            options={fnbStores}
                            getOptionLabel={(store) => `${store.name.toUpperCase()}-${store.storeCode.toUpperCase()}`}
                            value={selectedStore}
                            onChange={(e, newValue) => onChangeStore(newValue)}
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Store"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                            disabled={!!currentFood}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"sku"}
                            label={"SKU"}
                            variant={"outlined"}
                            {...register("sku", {
                              required: {
                                value: true,
                                message: "SKU cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.sku}
                            helperText={formErrors?.sku?.message}
                            onChange={(event) => validateText(event)}
                            onBlur={(e) => handleBlurOnSKU(e.target.value)}
                            disabled={!!currentFood}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Autocomplete
                            options={fnbCategories}
                            getOptionLabel={(category) =>
                              `${category.name.toUpperCase()}`
                            }
                            value={selectedCategory}
                            onChange={(e, newValue) =>
                              onChangeCategory(newValue)
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Category"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"name"}
                            label={"Name"}
                            variant={"outlined"}
                            {...register("name", {
                              required: {
                                value: true,
                                message: "Name cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.name}
                            helperText={formErrors?.name?.message}
                            onChange={(event) => validateText(event)}
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"nameInChinese"}
                            label={"姓名"}
                            variant={"outlined"}
                            {...register("nameInChinese")}
                            autoComplete={"off"}
                            error={!!formErrors?.nameInChinese}
                            helperText={formErrors?.nameInChinese?.message}
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"description"}
                            label={"Description"}
                            variant={"outlined"}
                            {...register("description")}
                            autoComplete={"off"}
                            error={!!formErrors?.description}
                            helperText={formErrors?.description?.message}
                            onChange={(event) => validateText(event)}
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"descriptionInChinese"}
                            label={"描述"}
                            variant={"outlined"}
                            {...register("descriptionInChinese")}
                            autoComplete={"off"}
                            error={!!formErrors?.descriptionInChinese}
                            helperText={
                              formErrors?.descriptionInChinese?.message
                            }
                            disabled={
                              isComponentEditDisabled
                            }
                          />
                        </Grid> */}

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"price"}
                            label={"Unit Price"}
                            type={"double"}
                            variant={"outlined"}
                            {...register("price", {
                              required: {
                                value: true,
                                message: "Price cannot be blank.",
                              },
                              pattern: {
                                value: /^\d*\.?\d*$/,
                                message:
                                  "Unit Price may contain only numbers (0-9).",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.price}
                            helperText={formErrors?.price?.message}
                            onChange={(event) => validateText(event)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"takeawayFee"}
                            label={"Takeaway Fee"}
                            type={"double"}
                            variant={"outlined"}
                            {...register("takeawayFee", {
                              required: {
                                value: false,
                                message: "Takeaway Fee cannot be blank.",
                              },
                              pattern: {
                                value: /^\d*\.?\d*$/,
                                message:
                                  "Takeway Fee may contain only numbers (0-9).",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.takeawayFee}
                            helperText={formErrors?.takeawayFee?.message}
                            onChange={(event) => validateText(event)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <DateTimeController
                            control={control}
                            label={"Active From"}
                            name={"activeFrom"}
                            value={activeFrom}
                            format={"dd/MM/yyyy HH:mm"}
                            onChange={onChangeDate_ActiveFrom}
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <DateTimeController
                            control={control}
                            label={"Active To"}
                            name={"activeTo"}
                            value={activeTo}
                            format={"dd/MM/yyyy HH:mm"}
                            onChange={onChangeDate_ActiveTo}
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"seqNo"}
                            label={"Seq. No."}
                            type={"double"}
                            variant={"outlined"}
                            {...register("seqNo", {
                              required: {
                                value: true,
                                message: "Seq No. cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.seqNo}
                            helperText={formErrors?.seqNo?.message}
                            onChange={(event) => validateText(event)}
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("status")}
                                checked={enabledSwitchValue}
                                onChange={(e) =>
                                  setEnabledSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Enable"
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>Settings</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("promote")}
                                checked={promoteSwitchValue}
                                onChange={(e) =>
                                  setPromoteSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Promote"
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("popular")}
                                checked={popularSwitchValue}
                                onChange={(e) =>
                                  setPopularSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Popular"
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("noStock")}
                                checked={noStockSwitchValue}
                                onChange={(e) =>
                                  setNoStockSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Sold Out"
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("adminUse")}
                                checked={adminUseSwitchValue}
                                onChange={(e) =>
                                  setAdminUseSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Admin Use"
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            checked={isPresetMeal}
                            control={<Switch {...register("isPresetMeal")} />}
                            label="Redemption"
                            disabled={
                              !!(
                                currentFood &&
                                foodId &&
                                !auth.checkModulePrivilege(
                                  PrivilegeModules.fnb_food,
                                  PrivilegeActions.edit,
                                )
                              )
                            }
                          />
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>Icons</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("chefRecommend")}
                                checked={chefRecommendSwitchValue}
                                onChange={(e) =>
                                  setChefRecommendSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Chef Recommend"
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("vegetarian")}
                                checked={vegetarianSwitchValue}
                                onChange={(e) =>
                                  setVegetarianSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Vegetarian"
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("healthierChoice")}
                                checked={healthierChoiceSwitchValue}
                                onChange={(e) =>
                                  setHealthierChoiceSwitchValue(
                                    e.target.checked,
                                  )
                                }
                              />
                            }
                            label="Healthier Choice"
                            disabled={isComponentEditDisabled}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Pictures</Typography>
                  </Grid>
                  <Grid item xs={12} className={"table"}>
                    <DataTable
                      fixedHeader={true}
                      persistTableHead={true}
                      columns={columnsForFoodImage}
                      data={images}
                    />
                  </Grid>
                  <Grid item xs={12} className={"table-action"}>
                    <IconButton
                      onClick={createNewRowForImage}
                      disabled={rowsInvalid || isComponentEditDisabled}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Add-Ons</Typography>
                  </Grid>
                  {foodAddOnList &&
                    foodAddOnList.map((addOn, index) => (
                      <Grid
                        item
                        xs={12}
                        key={`add-on-${addOn.entityId}-${index}`}
                      >
                        <FoodAddon
                          addOn={addOn}
                          addOnIndex={index}
                          disabled={false}
                          onChangeAddOn={onChangeAddOn}
                          onDeleteAddOn={onDeleteAddOn}
                          onAddNewRowForAddOnOption={onAddNewRowForAddOnOption}
                          onChangeAddOnOption={onChangeAddOnOption}
                          onDeleteAddOnOption={onDeleteAddOnOption}
                          handleBlurAddOn={handleBlurAddOn}
                          handleBlurAddOnOption={handleBlurAddOnOption}
                          itemList={storeFood}
                        />
                      </Grid>
                    ))}
                  <Grid item xs={12} className={"table-action"}>
                    <IconButton
                      onClick={onAddNewRowForAddOn}
                      disabled={rowsInvalid || isComponentEditDisabled}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Schedules</Typography>
                  </Grid>
                  <Grid item xs={12} className={"table"}>
                    <DataTable
                      fixedHeader={true}
                      persistTableHead={true}
                      columns={columnsForSchedule}
                      data={schedules}
                    />
                  </Grid>
                  <Grid item xs={12} className={"table-action"}>
                    <IconButton
                      onClick={createNewRowForSchedule}
                      disabled={rowsInvalid || isComponentEditDisabled}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Special Prices</Typography>
                  </Grid>
                  <Grid item xs={12} className={"table"}>
                    <DataTable
                      fixedHeader={true}
                      persistTableHead={true}
                      columns={columnsForSpecialPrice}
                      data={specialPrices}
                    />
                  </Grid>
                  <Grid item xs={12} className={"table-action"}>
                    <IconButton
                      onClick={createNewRowForSpecialPrice}
                      disabled={rowsInvalid || isComponentEditDisabled}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      }
    </>
  );
};

export default withRouter(FnbFoodCard);
