import { FormProvider, useForm } from "react-hook-form";
import { Button, Grid } from "@mui/material";
import CustomizedSteppers from "./components/Stepper";
import { FnbSupplierOrderCardComponent } from "./FnbSupplierOrderComponent";
import { FnbSupplierItemListComponent } from "./FnbSupplierItemListComponent";
import { PageWrapper } from "./components/PageWrapper";
import React, { useMemo, useState } from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import { fnbSupplierOrderSchema } from "./schema/fnb-supplier-order-schema";
import { useSupplierOrderApi } from "./api/useSupplierOrderApi";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const Actions = ({
  history,
  saveAction,
  submitAction,
  receiveAction,
  orderData,
}) => {
  const isShowSaveBtn = useMemo(
    () => !orderData.status || orderData.status === "PENDING",
    [orderData],
  );
  const isShowSubmit = useMemo(
    () => orderData.status === "PENDING" && orderData.items.length > 0,
    [orderData],
  );
  const isShowRecvBtn = useMemo(
    () => orderData.status && orderData.status === "SENT_TO_SUPPLIER",
    [orderData],
  );
  return (
    <>
      <Button
        className={"btn-cancel"}
        onClick={() => history.push("/app/fnb-supplier-order")}
      >
        Back
      </Button>

      {isShowSaveBtn && (
        <Button className={"primary"} onClick={saveAction} type="submit">
          Save
        </Button>
      )}
      {isShowSubmit && (
        <Button className={"primary"} onClick={submitAction} type="submit">
          Save/Submit
        </Button>
      )}
      {isShowRecvBtn && (
        <Button className={"primary"} onClick={receiveAction} type="submit">
          Received
        </Button>
      )}
    </>
  );
};

export function OrderForm({ orderData, pageTitle, prerequisites }) {
  const [isLocalPageLoading, setLocalPageLoading] = useState(false);
  const history = useHistory();
  const orderId = useMemo(() => orderData.orderNumber, [orderData]);
  const { updateOrder, placeOrder, sendOrderToSupplier, markAsReceived } =
    useSupplierOrderApi();

  // Forms
  const methods = useForm({
    values: orderData,
    mode: "all",
    resolver: joiResolver(fnbSupplierOrderSchema, { allowUnknown: true }),
  });
  const formFieldData = methods.watch();

  const selectedStoreCode = methods.watch("deliveryTo.storeCode");
  const availableSupplierList = useMemo(() => {
    if (!selectedStoreCode) {
      return [];
    }

    return prerequisites.supplierList.filter((s) =>
      s.stores.find((ss) => ss.storeCode === selectedStoreCode),
    );
  }, [prerequisites.supplierList, selectedStoreCode]);

  const selectedVendorCode = methods.watch("supplier.vendorCode");
  const selectedSupplier = useMemo(() => {
    return availableSupplierList.find(
      (s) => s.vendorCode === selectedVendorCode,
    );
  }, [selectedVendorCode, availableSupplierList]);
  const supplierItems = useMemo(() => {
    const itemIds = (selectedSupplier?.items || []).map((item) => item._id);
    return prerequisites.itemList.filter((i) => itemIds.includes(i._id));
  }, [prerequisites.itemList, selectedSupplier]);

  const currentStep = useMemo(() => {
    if (formFieldData.status === "RECEIVED_GOODS") return 6;
    if (formFieldData.status === "SENT_TO_SUPPLIER") return 4;
    if (formFieldData.status === "PENDING") return 3;
    if (formFieldData.items.length > 0) return 2;
    if (
      formFieldData?.deliveryDate &&
      formFieldData?.deliveryTo?.storeCode &&
      formFieldData?.supplier?.vendorCode
    )
      return 1;
    return 0;
  }, [formFieldData]);

  const saveAction = (data, showNotify = true) => {
    setLocalPageLoading(true);
    const saveActionApi = (data) => {
      if (orderId) return updateOrder(orderId, data);
      return placeOrder(data);
    };

    return saveActionApi(data)
      .then((result) => result.data)
      .then((order) => {
        methods.setValue("orderNumber", order.orderNumber);
        return order;
      })
      .then((order) => order.orderNumber)
      .then((orderNumber) => {
        if (showNotify)
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `Purchase order ${orderNumber} has been created.`,
            willClose(popup) {
              history.push(`/app/fnb-supplier-order/${orderNumber}`);
              history.go(0);
            },
          });
        return orderNumber;
      })
      .finally(() => {
        setLocalPageLoading(false);
      });
  };
  const submitAction = (data) => {
    setLocalPageLoading(true);
    saveAction(data, false)
      .then(() => sendOrderToSupplier(orderId))
      .then((result) => result.data)
      .then((order) => order.orderNumber)
      .then((orderNumber) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Purchase order ${orderNumber} has been submited to supplier.`,
          willClose(popup) {
            history.go(0);
          },
        });
      })
      .finally(() => {
        setLocalPageLoading(false);
      });
  };
  const receiveAction = () => {
    setLocalPageLoading(true);
    markAsReceived(orderId)
      .then((result) => result.data)
      .then((order) => order.orderNumber)
      .then((orderNumber) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Purchase order ${orderNumber} has been received.`,
          willClose(popup) {
            history.go(0);
          },
        });
      })
      .finally(() => {
        setLocalPageLoading(false);
      });
  };
  const formSaveHandler = methods.handleSubmit(saveAction);
  const saveAndSubmit = methods.handleSubmit(submitAction);
  const isReadOnly = useMemo(
    () =>
      formFieldData.status === "RECEIVED_GOODS" ||
      formFieldData.status === "SENT_TO_SUPPLIER",
    [formFieldData.status],
  );
  return (
    <>
      <FormProvider {...methods}>
        <PageWrapper
          title={pageTitle}
          action={
            <>
              <Actions
                orderData={orderData}
                history={history}
                saveAction={formSaveHandler}
                submitAction={saveAndSubmit}
                receiveAction={receiveAction}
              />
            </>
          }
        >
          <Grid item xs={12} display={{ xs: "none", lg: "block" }}>
            <CustomizedSteppers activeStep={currentStep} />
          </Grid>

          <Grid item xs={12} disabled={isLocalPageLoading}>
            <FnbSupplierOrderCardComponent
              prerequisites={prerequisites}
              supplierList={availableSupplierList}
              readOnly={isReadOnly}
            />
          </Grid>

          <Grid item xs={12} disabled={isLocalPageLoading}>
            <FnbSupplierItemListComponent
              readOnly={isReadOnly}
              supplierList={availableSupplierList}
              selectedSupplier={selectedSupplier}
              supplierItems={supplierItems}
            />
          </Grid>
        </PageWrapper>
      </FormProvider>
    </>
  );
}
