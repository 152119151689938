import Joi from "joi";

export const fnbSupplierOrderSchema = Joi.object({
  contactName: Joi.string().required().min(1).max(200).label("Contact Name"),
  contactNumber: Joi.string()
    .required()
    .pattern(new RegExp(/^(6|8|9)\d{7}$/))
    .label("Mobile number"),
  supplier: Joi.object({
    vendorCode: Joi.string().required().label("Supplier"),
  }),
  deliveryTo: Joi.object({
    storeCode: Joi.string().required().label("Storee"),
  }),
  remark: Joi.string().optional().allow("").allow(null).label("Remark"),
  items: Joi.array().required().not(Joi.array().empty()).label("Item List"),
  orderNumber: Joi.optional(),
  deliveryDate: Joi.date().optional().label("Expected Delivery Date"),
  createdAt: Joi.date().optional(),
  updatedAt: Joi.date().optional(),
});