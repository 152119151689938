import { Autocomplete, TextField } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import React from "react";

export function StoreSwitcher({ onChange, storeList, value }) {
  return (
    <Autocomplete
      options={storeList}
      getOptionLabel={(store) => `${store.name.toUpperCase()}-${store.storeCode.toUpperCase()}`}
      value={value}
      onChange={(e, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField {...params} label={"Store"} variant={"outlined"} />
      )}
      popupIcon={<KeyboardArrowDown />}
    />
  );
}
