import { joiResolver } from "@hookform/resolvers/joi";
import { useParams, withRouter } from "react-router-dom";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import { PageWrapper } from "./components/PageWrapper";
import { useRoleAuthorization } from "../../hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useVoucherSetupSave } from "./api/useVoucherSetupSave";
import { useForm } from "react-hook-form";
import { useVoucherRuleQuery } from "./api/useVoucherRuleQuery";
import { uniqBy } from "lodash/array";
import Swal from "sweetalert2";
import { useVoucherSetupDelete } from "./api/useVoucherSetupDelete";
import {
  triggerConfirmationMessage,
  triggerErrorMessage,
  triggerSuccessMessage,
} from "./helper/notification-helper";
import { createVoucherSchema } from "./validation/create-voucher-schema";
import { FnbVoucherCodeGrid } from "./FnbVoucherCodeGrid";
import GenerateVoucher from "./components/VoucherCodes/GenerateVoucher";
import moment from "moment/moment";
import downloadCSV from "../../helper/download-csv";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Actions = ({ history, saveAction, deleteAction }) => {
  const { auth } = useRoleAuthorization();
  return (
    <>
      <Button
        className={"btn-cancel"}
        onClick={() => history.push("/app/fnb-voucher-setup")}
      >
        Back
      </Button>
      {/* <Button className={"warning"} color={"warning"} onClick={deleteAction}>
        Delete
      </Button> */}
      <Button className={"primary"} onClick={saveAction} type="submit">
        Save
      </Button>
    </>
  );
};

const FnbVoucherSetupCard = (props) => {
  const params = useParams();
  const voucherId = useMemo(() => params["voucherId"], [params]);
  const isNew = useMemo(() => params["voucherId"] === "new", [params]);
  const pageTitle = useMemo(
    () =>
      isNew
        ? "F&B Voucher Setup Card - New"
        : `F&B Voucher Setup Card - ${voucherId}`,
    [isNew],
  );

  // APIs
  const { updateVoucherRule, createVoucherRule, isLoading } =
    useVoucherSetupSave();
  const {
    isLoading: voucherQuerying,
    queryVoucher,
    queryVoucherRulesPrerequisites,
  } = useVoucherRuleQuery();
  const { isLoading: voucherDeleting, deleteVoucherRule } =
    useVoucherSetupDelete();
  const isPageLoading = useMemo(
    () => !!(isLoading | voucherQuerying | voucherDeleting),
    [isLoading, voucherQuerying, voucherDeleting],
  );
  const [voucherData, setVoucherData] = useState({});
  const [prerequisites, setPrerequisites] = useState({});
  const [refresh, setRefresh] = useState(true);

  // Forms
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "all",
    defaultValues: voucherData,
    values: voucherData,
    resolver: joiResolver(createVoucherSchema),
  });
  // Init Process
  useMemo(() => {
    queryVoucherRulesPrerequisites().then((voucherRulesPrerequisites) => {
      setPrerequisites((prev) => voucherRulesPrerequisites);
    });
  }, []);

  const selectedStores = watch("stores", []);
  const selectedStoresText = useMemo(() => {
    if (selectedStores.length === 0) {
      return "ALL STORES";
    }
    return selectedStores
      .map(
        (selectedStore) =>
          (prerequisites?.stores || []).find(
            (store) => store.storeId === selectedStore.storeId,
          )?.name,
      )
      .join();
  }, [prerequisites, selectedStores]);

  useEffect(() => {
    document.title = "New - F&B Voucher Setup Card";
  }, []);

  useEffect(() => {
    if (!isNew) {
      queryVoucher(voucherId)
        .then((voucherResponse) => {
          setVoucherData(voucherResponse);
          document.title = `${voucherId} - F&B Voucher Setup Card`;
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            text: e.response?.data?.message || e.message,
          }).then(() => props.history.goBack());
        });
    }
  }, [isNew, voucherId]);

  const saveAction = useCallback(
    (voucherData) => {
      console.log(voucherData);
      if (isNew) {
        createVoucherRule(voucherData)
          .then((res) => {
            setVoucherData(res);
            return res;
          })
          .then((res) => {
            console.log(res);
            props.history.push(`/app/fnb-voucher-setup/${res.incrementId}`);
            triggerSuccessMessage(
              `F&B voucher setup ${res.incrementId} has been created.`,
            );
          })
          .catch((err) =>
            triggerErrorMessage(
              err?.response?.data?.response?.message || err.message,
            ),
          );
      } else {
        updateVoucherRule(voucherId, voucherData)
          .then((res) => {
            setVoucherData(res);
            return res;
          })
          .then((res) =>
            triggerSuccessMessage(
              `F&B voucher setup ${res.incrementId} has been updated.`,
            ),
          )
          .catch((err) =>
            triggerErrorMessage(
              err?.response?.data?.response?.message || err.message,
            ),
          );
      }
    },
    [createVoucherRule, isNew, updateVoucherRule, voucherId],
  );

  const deleteAction = useCallback(async () => {
    triggerConfirmationMessage(
      `Are you sure you want to delete the F&B voucher setup ${voucherData.incrementId}?`,
    )
      .then(async (result) => {
        result.isConfirmed &&
          (await deleteVoucherRule(voucherData.incrementId).then((res) => {
            triggerSuccessMessage(
              `F&B voucher setup ${voucherData.incrementId} has been deleted.`,
            );
            props.history.push("/app/fnb-voucher-setup");
            return res;
          }));
      })
      .catch((err) =>
        triggerErrorMessage(err.response?.data?.message || err.message),
      );
  }, [deleteVoucherRule, props.history, voucherData.incrementId]);

  const formSaveHandler = handleSubmit(saveAction);

  const handleChangeStores = (event) => {
    const storeList = event.target.value || [];
    const removeSets = new Set();
    const count = {};
    storeList.forEach((store) => {
      count[store.storeId] = (count[store.storeId] || 0) + 1;
      if (count[store.storeId] > 1) {
        removeSets.add(store.storeId);
      }
    });

    setValue(
      "stores",
      uniqBy(event.target.value, (o) => o.storeId)
        .filter((o) => !removeSets.has(o.storeId))
        .map((store) => ({
          storeId: store.storeId,
        })),
    );
  };

  const isStoreSelected = useCallback(
    (store) => {
      return !!selectedStores.find(
        (selected) => selected.storeId === store.storeId,
      );
    },
    [selectedStores],
  );

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const formErrorHelper = useCallback(
    (fieldName, defaultText = "") => {
      return {
        error: errors[fieldName],
        helperText: !!errors[fieldName]?.message ? defaultText : ""
      };
    },
    [errors],
  );

  const handleGenerateCodeSuccess = (data) => {
    if (!data?.length) {
      triggerErrorMessage("No records found.");
    }

    const today = moment().format("YYYYMMDD_HHmmss");
    const filename = `GENERATED_VOUCHER_${today}.csv`;
    // column names
    const columns = Object.keys(data[0]);
    downloadCSV(data, filename, columns);
    setRefresh(true);
  };

  const handleGenerateCodeFailed = (err) => {
    triggerErrorMessage(err?.response?.data?.response?.message || err.message);
  };

  return (
    <>
      {isPageLoading && (
        <Backdrop open={isPageLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      <PageWrapper
        title={pageTitle}
        action={
          <>
            <Actions
              history={props.history}
              saveAction={formSaveHandler}
              deleteAction={deleteAction}
            />
            <GenerateVoucher
              voucherData={voucherData}
              onSuccess={handleGenerateCodeSuccess}
              onError={handleGenerateCodeFailed}
            />
          </>
        }
      >
        <Grid className={"form"} disabled={isPageLoading}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12}>
              <Typography variant={"h2"}>General</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {/*IncrementID*/}
                {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    autoComplete={"off"}
                    id={"incrementId"}
                    label={"Setup No."}
                    variant={"outlined"}
                    helperText={"XXX"}
                    {...formErrorHelper("incrementId", "")}
                    {...register("incrementId", { disabled: true })}
                  />
                </Grid> */}
                {/*Available Store*/}
                <Grid item xs={12} xl={6}>
                  <Select
                    fullWidth={true}
                    multiple
                    displayEmpty
                    value={selectedStores}
                    label={"Store"}
                    variant={"outlined"}
                    input={<OutlinedInput label={"Store"} />}
                    renderValue={() => selectedStoresText}
                    IconComponent={ArrowDownIcon}
                    MenuProps={MenuProps}
                    {...register("stores", {
                      onChange: handleChangeStores,
                      setValueAs: (stores) => stores || [],
                    })}
                  >
                    {(prerequisites?.stores || []).map((store) => (
                      <MenuItem key={store.storeId} value={store}>
                        <Checkbox checked={isStoreSelected(store)} />
                        <ListItemText primary={`${store.name}-${store.storeCode}`} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* Status */}
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          {...register("isActive")}
                          checked={watch("isActive")}
                        />
                      }
                      label="Enable"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={"form"} disabled={isPageLoading} sx={{ mt: 2 }}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Typography variant={"h2"}>Voucher Details</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {/*Item No*/}
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    autoComplete={"off"}
                    id={"itemNo"}
                    label={"Item No."}
                    variant={"outlined"}
                    {...formErrorHelper("itemNo", "Item No. cannot be blank.")}
                    {...register("itemNo", { disabled: false })}
                  />
                </Grid>
                {/*Location Code*/}
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    autoComplete={"off"}
                    id={"locationCode"}
                    label={"Location Code"}
                    variant={"outlined"}
                    {...formErrorHelper("locationCode", "Location Code cannot be blank.")}
                    {...register("locationCode", { disabled: false })}
                  />
                </Grid>

                {/*Discount Amount*/}
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    autoComplete={"off"}
                    id={"amount"}
                    label={"Voucher Amount"}
                    variant={"outlined"}
                    type={"double"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    {...formErrorHelper("amount", "Voucher Amount cannot be blank.")}
                    {...register("amount", {
                      disabled: false,
                      min: 0,
                      valueAsNumber: true,
                    })}
                  />
                </Grid>
                {/*Min Cart Total*/}
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    autoComplete={"off"}
                    id={"minTotalCartAmount"}
                    label={"Min. Total Payable"}
                    variant={"outlined"}
                    type={"double"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    {...formErrorHelper("minTotalCartAmount", "Min. Total Payable cannot be blank.")}
                    {...register("minTotalCartAmount", {
                      disabled: false,
                      min: 0,
                      valueAsNumber: true,
                    })}
                  />
                </Grid>

                {/*Validity*/}
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    autoComplete={"off"}
                    id={"validityPeriod"}
                    label={"Validity Period (Day)"}
                    variant={"outlined"}
                    type={"tel"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">Day(s)</InputAdornment>
                      ),
                    }}
                    {...formErrorHelper("validityPeriod", "Validity Period cannot be blank.")}
                    {...register("validityPeriod", {
                      disabled: false,
                      min: 0,
                      valueAsNumber: true,
                    })}
                  />
                </Grid>
                {/*Voucher Length*/}
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    autoComplete={"off"}
                    id={"voucherLength"}
                    label={"Voucher Length"}
                    variant={"outlined"}
                    type={"tel"}
                    {...formErrorHelper("voucherLength", "Voucher Length must be greater than 5.")}
                    {...register("voucherLength", {
                      disabled: false,
                      min: 5,
                      valueAsNumber: true,
                    })}
                  />
                </Grid>

                {/*Voucher Prefix*/}
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    autoComplete={"off"}
                    id={"voucherPrefix"}
                    label={"Voucher Prefix"}
                    variant={"outlined"}
                    type={"text"}
                    {...formErrorHelper("voucherPrefix", "")}
                    {...register("voucherPrefix")}
                  />
                </Grid>
                {/*Voucher Suffix*/}
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    autoComplete={"off"}
                    id={"voucherSuffix"}
                    label={"Voucher Suffix"}
                    variant={"outlined"}
                    type={"text"}
                    {...formErrorHelper("voucherSuffix", "")}
                    {...register("voucherSuffix")}
                  />
                </Grid>

                {/*Voucher Print Template*/}
                <Grid item xs={12}>
                  <TextField
                    autoComplete={"off"}
                    rows={3}
                    multiline={true}
                    id={"voucherPrintTemplate"}
                    label={"Print Template"}
                    variant={"outlined"}
                    helperText={
                      "Using following codes: {{qrCodeURL}}, {{voucherCode}}, {{expirationDate}}, {{voucherAmount}}"
                    }
                    type={"text"}
                    {...formErrorHelper("voucherPrintTemplate", "Voucher Template cannot be blank.")}
                    {...register("voucherPrintTemplate", {
                      disabled: false,
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={"form"} disabled={isPageLoading} sx={{ mt: 2 }}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12}>
              <Typography variant={"h2"}>Voucher Code</Typography>
            </Grid>
            <Grid item xs={12}>
              <FnbVoucherCodeGrid
                voucherData={voucherData}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </Grid>
          </Grid>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default withRouter(FnbVoucherSetupCard);
