import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules } from "../../data/privileges.enum";
import downloadCSV from "../../helper/download-csv";
import moment from "moment";
import DateFilter from "../../components/DateFilter";

const FnbReport = (props) => {
  const { isLoading, sendRequest } = useHttpRequest();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(null);
  const [fnbStores, setFnbStores] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  const [message, setMessage] = useState("");
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [fnbReportList, setFnbReportList] = useState([]);
  const [invalidToDownload, setInvalidToDownload] = useState(false);

  // handling form when adding / editing
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    control,
    setError,
    getValues,
    setValue,
  } = useForm({ mode: "all" });

  const { auth } = useRoleAuthorization();

  useEffect(() => {
    document.title = "F&B Report";
    loadPrerequisites();
  }, []);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
      });
      setMessage("");
    }
  }, [message]);

  useEffect(() => {
    if (errMessage) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });

      setErrMessage("");
    }
  }, [errMessage]);

  useEffect(() => {
    const invalid = !selectedReportType || !fromDate;
    setInvalidToDownload(invalid);
  }, [selectedReportType, selectedStore, fromDate]);

  const loadPrerequisites = () => {
    sendRequest(`/v1/fnb-report/get-prerequisites`, "GET", {}).then(
      (response) => {
        let prerequisites = response.data.prerequisites;
        setFnbStores(prerequisites.fnbStores);

        //setup available report list of login user
        prerequisites.fnbReports.forEach((r) => {
          if (
            auth.checkModulePrivilege(PrivilegeModules.fnb_report, r.rpt_code)
          ) {
            setFnbReportList((prevItems) => [
              ...prevItems,
              { rpt_code: r.rpt_code, rpt_name: r.rpt_name },
            ]);
          }
        });
      },
    );
  };

  const onChangeDate_fromDate = (date) => {
    setValue("fromDate", date);
    setFromDate(date);
  };

  const onChangeDate_toDate = (date) => {
    setValue("toDate", date);
    setToDate(date);
  };

  const handleSubmitForm = (data) => {
    let reportParams = {
      reportCode: selectedReportType.rpt_code,
      storeId: data.storeId,
      fromDate: moment(fromDate).format("YYYYMMDD"),
      toDate: moment(toDate || new Date()).format("YYYYMMDD"),
    };

    sendRequest(
      `/v1/fnb-report/generate-report-data`,
      "POST",
      reportParams,
    ).then((response) => {
      if (response.data.reportData.reportData.length == 0) {
        setErrMessage("There's no data!");
        return;
      }

      let reportData = response.data.reportData.reportData;
      let columns = response.data.reportData.columns;
      let fileName = response.data.reportData.fileName;

      downloadCSV(reportData, fileName, columns);
    });
  };

  const onChangeStore = (newValue) => {
    setValue("storeId", newValue.storeId);
    setSelectedStore(newValue);
  };

  const onChangeReportType = (newValue) => {
    setSelectedReportType(newValue);
  };

  return (
    <>
      {isLoading && (
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      {
        <form>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>F&B Report</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <Button
                onClick={() => props.history.push("/app/fnb-store-event/list")}
              >
                Cancel
              </Button>
              {
                <Button
                  className={"primary"}
                  type={"button"}
                  onClick={handleSubmit(handleSubmitForm)}
                  disabled={invalidToDownload}
                >
                  Download Report
                </Button>
              }
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>Filter</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} md={2}>
                          <Autocomplete
                            options={fnbStores}
                            getOptionLabel={(store) => `${store.name.toUpperCase()}-${store.storeCode.toUpperCase()}`}
                            value={selectedStore}
                            onChange={(e, newValue) => onChangeStore(newValue)}
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Store"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                          />
                        </Grid>

                        <Grid item xs={6} md={2}>
                          <DateFilter
                            label={"From"}
                            value={fromDate}
                            maxDate={toDate}
                            onChange={(newValue) => onChangeDate_fromDate(newValue)}
                          />
                        </Grid>

                        <Grid item xs={6} md={2}>
                          <DateFilter
                            label={"To"}
                            value={toDate}
                            minDate={fromDate}
                            onChange={(newValue) => onChangeDate_toDate(newValue)}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Autocomplete
                            options={fnbReportList}
                            getOptionLabel={(option) => option.rpt_name.toUpperCase()}
                            value={selectedReportType}
                            onChange={(e, newValue) =>
                              onChangeReportType(newValue)
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Report Type"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      }
    </>
  );
};

export default withRouter(FnbReport);
