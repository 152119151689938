import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  KeyboardArrowDown,
  PlaylistPlay,
  Search as SearchIcon,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useStyles from "./styles";
import MobileFnbFoodFilters from "./components/Mobile/MobileFnbFoodFilters";
import { fnbFoodTableColumnConfig } from "./data-table/fnb-food-table-column-config";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";

import { fnbFoodTableChefModeColumnConfig } from "./data-table/fnb-food-table-chef-mode-column-config";
import { OptionSelectComponent } from "../../components/FromElements/OptionComponent";

const FnbFoodList = (props) => {
  const classes = useStyles();

  // handle loading & http
  const { isLoading, sendRequest } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  // layout state
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();

  // filters
  const [textFilter, setTextFilter] = useState("");
  const [fnbStores, setFnbStores] = useState([]);
  const [storeFilter, setStoreFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [fnbCategories, setFnbCategories] = useState([]);
  const yesNoList = ["Yes", "No"];
  const [enableFilter, setEnableFilter] = useState(null);

  const [promoteFilter, setPromoteFilter] = useState(null);
  const [chefRecommendFilter, setChefRecommendFilter] = useState(null);
  const [vegetarianFilter, setVegetarianFilter] = useState(null);
  const [presetMealFilter, setPresetMealFilter] = useState(null);
  const [healthierChoiceFilter, setHealthierChoiceFilter] = useState(null);
  const [noStockFilter, setNoStockFilter] = useState(null);
  const [popularFilter, setPopularFilter] = useState(null);
  const [selectedFnbStoreCodes, setSelectedFnbStoreCodes] = useState(null);
  const [prerequisiteChecked, setPrerequisiteChecked] = useState(false);

  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false); // refresh list

  const [fnbFoodList, setFnbFoodList] = useState([]);
  const smallDeviceWidth = 1200;

  // pagination states
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);

  const [itemPickerMode, setItemPickerMode] = useState(false);
  const [chefMode, setChefMode] = useState(false);

  const mobileFilterProps = {
    fnbStores,
    storeFilter,
    onChangeStoreFilter,
    fnbCategories,
    categoryFilter,
    setCategoryFilter,
    noStockFilter,
    setNoStockFilter,
    yesNoList,
    promoteFilter,
    setPromoteFilter,
    chefRecommendFilter,
    setChefRecommendFilter,
    vegetarianFilter,
    setVegetarianFilter,
    healthierChoiceFilter,
    setHealthierChoiceFilter,
    enableFilter,
    setEnableFilter,
    popularFilter,
    setPopularFilter,
  };
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    document.title = "F&B Dish List";
    loadPrerequisites();

    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.fnb_food,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: setRefresh(true),
      });
    }
  }, [message]);

  useEffect(() => {
    if (errMessage) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });

      setErrMessage("");
    }
  }, [errMessage]);

  // filter data
  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!textFilter && prerequisiteChecked) {
      setRefresh(true);
    }
  }, [textFilter]);

  useEffect(() => {
    if (prerequisiteChecked) {
      setRefresh(true);
    }
  }, [
    prerequisiteChecked,
    storeFilter,
    categoryFilter,
    enableFilter,
    promoteFilter,
    chefRecommendFilter,
    vegetarianFilter,
    presetMealFilter,
    healthierChoiceFilter,
    noStockFilter,
    popularFilter,
  ]);

  useEffect(() => {
    if (width <= smallDeviceWidth) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <MobileFnbFoodFilters key={"rb-body"} {...mobileFilterProps} />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [fnbFoodList, enableFilter, width]);

  // set columns in data table
  const columns = fnbFoodTableColumnConfig(
    {
      allowedViewDetail: auth.checkModulePrivilege(
        PrivilegeModules.fnb_food,
        PrivilegeActions.view_detail,
      ),
      allowedDelete: auth.checkModulePrivilege(
        PrivilegeModules.fnb_food,
        PrivilegeActions.delete,
      ),
      allowedEnableDisable: auth.checkModulePrivilege(
        PrivilegeModules.fnb_food,
        PrivilegeActions.enable_disable,
      ),
      allowedEdit: auth.checkModulePrivilege(
        PrivilegeModules.fnb_food,
        PrivilegeActions.edit,
      ),
      chef_mode: auth.checkModulePrivilege(
        PrivilegeModules.fnb_food,
        PrivilegeActions.chef_mode,
        false,
      ),
      itemPickerMode,
    },
    handleNameClick,
    handleDeleteFnbFood,
    handleEditFnbFood,
    selectItem,
  );

  const columnsForChefMode = fnbFoodTableChefModeColumnConfig(
    {
      allowedEdit: auth.checkModulePrivilege(
        PrivilegeModules.fnb_food,
        PrivilegeActions.edit,
      ),
    },
    handleEditFnbFood,
  );

  function selectItem(rowItem, index, value) {
    if (value) {
      props.setSelectedItemList([...props.selectedItemList, rowItem]);
    } else {
      const indexOfItem = props.selectedItemList.findIndex(
        (item) => item.entityId === rowItem.entityId,
      );
      const selectedItems = [...props.selectedItemList];
      selectedItems.splice(indexOfItem, 1);
      props.setSelectedItemList(selectedItems);
    }

    const foods = [...fnbFoodList];
    foods[index].selected = value;
    setFnbFoodList(foods);
  }

  async function handleEditFnbFood(
    food,
    type,
    toggle = false,
    toggleValue = false,
  ) {
    if (toggle) {
      food[type] = toggleValue;
      let tmpFood = JSON.parse(JSON.stringify(food));
      tmpFood.price = Number(tmpFood.price);
      await sendRequest(`/v1/fnb-food/${food.entityId}`, "PUT", tmpFood);
    } else {
      props.history.push(`card/${food.entityId}`);
    }
  }

  function handleDeleteFnbFood(food) {
    if (food) {
      Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to delete the F&B dish ${food.sku.toUpperCase()}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            const result = await sendRequest(
              `/v1/fnb-food/${food.entityId}`,
              "DELETE",
            );
            if (result?.status === 200 && result?.data?.deleted) {
              setMessage(
                `F&B dish ${food.sku.toUpperCase()} has been deleted.`,
              );
            }
          }
        })
        .catch((err) => {
          setErrMessage(err.message);
        });
    }
  }

  const loadPrerequisites = () => {
    sendRequest(`/v1/fnb-food/get-prerequisites`, "GET", {}).then(
      (response) => {
        let prerequisites = response.data.prerequisites;
        let selectedFnbStoreCodes = prerequisites.selectedFnbStoreCodes;
        setPrerequisiteChecked(true);

        setChefMode(
          auth.checkModulePrivilege(
            PrivilegeModules.fnb_food,
            PrivilegeActions.chef_mode,
            false,
          ),
        );

        if (props.itemPickerMode) {
          setItemPickerMode(props.itemPickerMode);

          setFnbStores(
            prerequisites.fnbStores.filter((s) => {
              return s.storeCode == props.selectedStore.storeCode;
            }),
          );
          setSelectedFnbStoreCodes([props.selectedStore.storeCode]);
          setEnableFilter("YES");
        } else if (
          auth.checkModulePrivilege(
            PrivilegeModules.fnb_food,
            PrivilegeActions.chef_mode,
            false,
          )
        ) {
          setFnbStores(
            prerequisites.fnbStores.filter((store) =>
              selectedFnbStoreCodes.includes(store.storeCode),
            ),
          );
          setSelectedFnbStoreCodes(selectedFnbStoreCodes);
          setNoStockFilter("YES");
        } else {
          setFnbStores(prerequisites.fnbStores);
          setRefresh(true);
        }
      },
    );
  };

  function filter() {
    // set list filters
    getPaginatedData(page, pageSize, getFilterValues(), sortBy).then(
      (response) => {
        const foods = response.data?.foods || [];

        if (itemPickerMode) {
          foods.forEach((food) => {
            const isSelected = props.selectedItemList.some(
              (selectedFood) => selectedFood.entityId === food.entityId,
            );
            food.selected = !!isSelected;
          });
        }

        setFnbFoodList(foods);
        setCollectionSize(response.data?.total);
        return () => {
          return foods;
        };
      },
    );
  }

  function getFilterValues() {
    return {
      text: textFilter.trim(),
      storeId: storeFilter ? storeFilter.storeId : undefined,
      categoryId: categoryFilter ? categoryFilter.entityId : undefined,
      enable: enableFilter,
      promote: promoteFilter,
      chefRecommend: chefRecommendFilter,
      vegetarian: vegetarianFilter,
      isPresetMeal: presetMealFilter,
      healthierChoice: healthierChoiceFilter,
      noStock: noStockFilter,
      popular: popularFilter,
      selectedFnbStoreCodes: selectedFnbStoreCodes,
    };
  }

  function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
    return sendRequest(
      `/v1/fnb-food/filter?filters=${JSON.stringify(
        filters,
      )}&page=${page}&pageSize=${pageSize}
        ${sortBy?.column && sortBy?.direction
        ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
        : ""
      }`,
      "GET",
      {},
    );
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  // go to edit view
  async function handleNameClick(entityId) {
    if (
      auth.checkModulePrivilege(
        PrivilegeModules.fnb_food,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push(`card/${entityId}`);
    }
  }

  function onChangeStoreFilter(newValue) {
    setStoreFilter(newValue);
    setCategoryFilter(null);
    setFnbCategories(
      newValue
        ? fnbStores.filter((c) => {
          return c.storeId == newValue.storeId;
        })[0].categories
        : [],
    );
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>F&B Dish List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {auth.checkModulePrivilege(
            PrivilegeModules.fnb_food,
            PrivilegeActions.add,
          ) &&
            !auth.checkModulePrivilege(
              PrivilegeModules.fnb_food,
              PrivilegeActions.chef_mode,
              false,
            ) &&
            !itemPickerMode && (
              <Button
                className={"primary"}
                onClick={() => props.history.push("card")}
              >
                New
              </Button>
            )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={10} sm={10} md={10} lg={2}>
                <TextField
                  autoComplete={"off"}
                  id={"text-searchbox"}
                  variant={"outlined"}
                  value={textFilter}
                  onChange={(e) => setTextFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {width >= smallDeviceWidth ? (
                <>
                  {!itemPickerMode && (
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                      <Autocomplete
                        id={"store-filter"}
                        options={fnbStores}
                        getOptionLabel={(store) =>
                          `${store.name.toUpperCase()}-${store.storeCode.toUpperCase()}`
                        }
                        value={storeFilter}
                        onChange={(e, newValue) =>
                          onChangeStoreFilter(newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Store"}
                            variant={"outlined"}
                          />
                        )}
                        popupIcon={<KeyboardArrowDown />}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"category-filter"}
                      options={fnbCategories}
                      getOptionLabel={(category) =>
                        !storeFilter
                          ? `${category.store.storeCode
                          } - ${category.name.toUpperCase()}`
                          : `${category.name.toUpperCase()}`
                      }
                      value={categoryFilter}
                      onChange={(e, newValue) => setCategoryFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Category"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  {!auth.checkModulePrivilege(
                    PrivilegeModules.fnb_food,
                    PrivilegeActions.chef_mode,
                    false,
                  ) &&
                    !itemPickerMode && (
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Autocomplete
                          id={"promote-filter"}
                          options={yesNoList}
                          getOptionLabel={(option) => option.toUpperCase()}
                          value={promoteFilter}
                          onChange={(e, newValue) => setPromoteFilter(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Promote"}
                              variant={"outlined"}
                            />
                          )}
                          popupIcon={<KeyboardArrowDown />}
                        />
                      </Grid>
                    )}
                  {!auth.checkModulePrivilege(
                    PrivilegeModules.fnb_food,
                    PrivilegeActions.chef_mode,
                    false,
                  ) &&
                    !itemPickerMode && (
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Autocomplete
                          id={"popular-filter"}
                          options={yesNoList}
                          getOptionLabel={(option) => option.toUpperCase()}
                          value={popularFilter}
                          onChange={(e, newValue) => setPopularFilter(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Popular"}
                              variant={"outlined"}
                            />
                          )}
                          popupIcon={<KeyboardArrowDown />}
                        />
                      </Grid>
                    )}
                  {!auth.checkModulePrivilege(
                    PrivilegeModules.fnb_food,
                    PrivilegeActions.chef_mode,
                    false,
                  ) &&
                    !itemPickerMode && (
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Autocomplete
                          id={"chefRecommend-filter"}
                          options={yesNoList}
                          getOptionLabel={(option) => option.toUpperCase()}
                          value={chefRecommendFilter}
                          onChange={(e, newValue) =>
                            setChefRecommendFilter(newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Chef Recommend"}
                              variant={"outlined"}
                            />
                          )}
                          popupIcon={<KeyboardArrowDown />}
                        />
                      </Grid>
                    )}
                  {!auth.checkModulePrivilege(
                    PrivilegeModules.fnb_food,
                    PrivilegeActions.chef_mode,
                    false,
                  ) &&
                    !itemPickerMode && (
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Autocomplete
                          id={"vegetarian-filter"}
                          options={yesNoList}
                          getOptionLabel={(option) => option.toUpperCase()}
                          value={vegetarianFilter}
                          onChange={(e, newValue) =>
                            setVegetarianFilter(newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Vegetarian"}
                              variant={"outlined"}
                            />
                          )}
                          popupIcon={<KeyboardArrowDown />}
                        />
                      </Grid>
                    )}
                  {!auth.checkModulePrivilege(
                    PrivilegeModules.fnb_food,
                    PrivilegeActions.chef_mode,
                    false,
                  ) &&
                    !itemPickerMode && (
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Autocomplete
                          id={"healthierChoice-filter"}
                          options={yesNoList}
                          getOptionLabel={(option) => option.toUpperCase()}
                          value={healthierChoiceFilter}
                          onChange={(e, newValue) =>
                            setHealthierChoiceFilter(newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Healthier Choice"}
                              variant={"outlined"}
                            />
                          )}
                          popupIcon={<KeyboardArrowDown />}
                        />
                      </Grid>
                    )}
                  {!itemPickerMode && (
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                      <Autocomplete
                        id={"noStock-filter"}
                        options={yesNoList}
                        getOptionLabel={(option) => option.toUpperCase()}
                        value={noStockFilter}
                        onChange={(e, newValue) => setNoStockFilter(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Sold Out"}
                            variant={"outlined"}
                          />
                        )}
                        popupIcon={<KeyboardArrowDown />}
                      />
                    </Grid>
                  )}
                  {!auth.checkModulePrivilege(
                    PrivilegeModules.fnb_food,
                    PrivilegeActions.chef_mode,
                    false,
                  ) &&
                    !itemPickerMode && (
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <OptionSelectComponent
                          label={"Redemption"}
                          value={presetMealFilter}
                          optionList={yesNoList}
                          onChange={setPresetMealFilter}
                        />
                      </Grid>
                    )}
                  {!itemPickerMode && (
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                      <Autocomplete
                        id={"enable-filter"}
                        options={yesNoList}
                        getOptionLabel={(option) => option.toUpperCase()}
                        value={enableFilter}
                        onChange={(e, newValue) => setEnableFilter(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Enable"}
                            variant={"outlined"}
                          />
                        )}
                        popupIcon={<KeyboardArrowDown />}
                      />
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => toggleRightbar(layoutDispatch)}
                    className={classes.drawerToggleBtn}
                  >
                    {layoutState.isRightbarOpened ? (
                      <ArrowBack className={classes.toggleRightBarIcon} />
                    ) : (
                      <PlaylistPlay className={classes.toggleRightBarIcon} />
                    )}
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={chefMode ? columnsForChefMode : columns}
                  data={fnbFoodList}
                  sortServer
                  onSort={sortByColumn}
                  pagination
                  paginationServer
                  paginationDefaultPage={page}
                  paginationPerPage={pageSize}
                  paginationTotalRows={collectionSize}
                  onChangeRowsPerPage={(count) =>
                    handleRowsPerPageChanged(count)
                  }
                  onChangePage={(page) => handlePageChanged(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(FnbFoodList);
