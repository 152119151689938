import { convertThisToCurrency } from "../../helper";
import { Link } from "react-router-dom";
import moment from "moment";

export const columns = [
  {
    name: "Order No.",
    selector: (row) => (
      <Link
        to={`/app/fnb-supplier-order/${row.orderNumber}`}
        title={row.orderNumber}
      >
        {row.orderNumber}
      </Link>
    ),
    sortable: true,
    wrap: true,
    sortField: "entityId",
  },
  {
    name: "Store",
    selector: (row) =>
      `${row.deliveryTo.storeCode}`,
    wrap: true,
    width: "100px",
  },
  {
    name: "Supplier",
    selector: (row) => row.supplier.name,
    wrap: true,
    width: "350px",
  },
  {
    name: "Contact Name",
    selector: (row) => row.contactName,
    wrap: true,
    width: "200px",
  },
  {
    name: "Contact No.",
    selector: (row) => row.contactNumber,
    wrap: true,
    width: "100px",
  },
  {
    name: "Order Status",
    selector: (row) => row.status,
    wrap: true,
    width: "150px",
  },
  {
    name: "Expected Delivery Date",
    selector: (row) => moment(row.deliveryDate).format("DD/MM/YYYY"),
    wrap: true,
    width: "130px",
  },
  {
    name: "Estimate Order Total",
    selector: (row) => convertThisToCurrency(+row.grandTotal),
    wrap: true,
    right: true,
    width: "120px",
  },
  {
    name: "Created By",
    selector: (row) => `${row?.createdBy?.name}-${row?.createdBy?.username}`,
    wrap: true,
    width: "350px",
  },
];
