import { useCallback, useMemo, useState } from "react";
import { Grid, InputAdornment, Switch, TextField } from "@mui/material";
import { convertThisToCurrency } from "../../../helper";
import { Search } from "@mui/icons-material";
import DataTable from "react-data-table-component";

export default function SupplierItemPopup(props) {
  const findRowIndex = useCallback(
    (row) => props.items.findIndex((i) => i._id === row._id),
    [props.items],
  );
  const handleItemEnable = (event, rowItem) => {
    const currentIndex = findRowIndex(rowItem);
    const newChecked = [...props.items];

    if (currentIndex === -1) {
      rowItem.qty = 1;
      newChecked.push(rowItem);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    props.setItems(newChecked.slice());
  };

  // State
  const [textFilter, setTextFilter] = useState("");
  const listProducts = useMemo(() => props.itemList, [props.itemList]);
  // Get List Of Items
  const filteredList = (list) => {
    return list.filter((item) => {
      let filterFlag = true;
      if (textFilter) {
        const compoundText =
          (item?.code || "").toLowerCase() +
          (item.description || "").toLowerCase();
        filterFlag &= compoundText.includes(textFilter.toLowerCase());
      }

      return filterFlag;
    });
  };

  const data = filteredList(listProducts);

  const handleSelectAll = (e, checked, data = []) => {
    if (checked) {
      const mapData = data.map((item) => {
        item = {
          ...item,
          qty: 1,
        };
        return item;
      });
      props.setItems(mapData);
    } else {
      props.setItems([]);
    }
  };

  const callBackFormRender = useCallback(
    (row) => {
      return (
        <Switch
          checked={findRowIndex(row) !== -1}
          onChange={(e) => handleItemEnable(e, row)}
        />
      );
    },
    [props.items],
  );

  const allCheckboxRender = useCallback(() => {
    return (
      <Switch
        checked={
          Object.keys(props.items).length === data.length && data.length > 0
        }
        onChange={(e, checked) => {
          handleSelectAll(e, checked, data);
        }}
      />
    );
  }, [props.items, data]);

  const columns = useMemo(() => {
    return [
      {
        name: allCheckboxRender(),
        width: "90px",
        sortable: false,
        selector: (row) => callBackFormRender(row),
      },
      {
        name: "Code",
        width: "90px",
        selector: (row) => row.sku,
        sortable: true,
      },
      {
        name: "Description",
        minWidth: "300px",
        wrap: true,
        cell: (row, index, column, id) => {
          return row.description;
        },
        selector: (row) => row.description,
        sortable: true,
      },
      {
        name: "UOM",
        width: "70px",
        selector: (row) => row.uom,
        sortable: true,
      },
      {
        name: "Unit Price",
        width: "100px",
        right: true,
        selector: (row) => row.price,
        cell: (row, index, column, id) => {
          return convertThisToCurrency(row.price.toFixed(2));
        },
        sortable: true,
      },
    ];
  }, [callBackFormRender, allCheckboxRender]);

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={6} sm={6} md={2} lg={2}>
        <TextField
          variant={"outlined"}
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          "& .rdt_TableCol_Sortable > span:before": {
            display: "none!important",
          },
        }}
      >
        <DataTable
          fixedHeader={true}
          persistTableHead={true}
          onRowClicked={(row, e) => {
            handleItemEnable(e, row);
          }}
          columns={columns}
          data={data}
          pagination
        />
      </Grid>
    </Grid>
  );
}
