import { useHttpRequest } from "../../../hooks";
import Swal from "sweetalert2";

export const useSupplierListApi = () => {
  const { isLoading, sendRequest } = useHttpRequest();

  const getOrderList = async (
    searchQuery,
    filters,
    pagination = { page: 1, pageSize: 10 },
    sortOrder = { sortField: "createdAt", direction: "desc" },
  ) => {
    return sendRequest(`/fnb-suppliers/search/`, "POST", {
      query: searchQuery,
      filters,
      pagination,
      sortOrder,
    })
      .then((response) => response?.data?.data)
      .catch((e) => {
        Swal.fire(
          "Error during query prerequisites.",
          e?.response?.data?.message || e.message,
          "error",
        );
        throw e;
      });
  };

  return {
    isLoading,
    getOrderList,
  };
};
