import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { convertThisToCurrency } from "../../helper";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SupplierItemPopup from "./components/SupplierItemPopup";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export function FnbSupplierItemListComponent(props) {
  const {
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const selectedItems = watch("items");
  const grandTotal = watch("grandTotal");
  const savedTaxAmt = watch("taxAmount");
  const setSelectedItems = (items) => setValue("items", items);
  const [totalPrice, setTotalPrice] = useState(grandTotal || 0);
  const [taxAmount, setTaxAmount] = useState(savedTaxAmt || 0);

  // Calculate Total on fly
  useEffect(() => {
    let totalTaxAmount = 0;
    setTotalPrice(
      (selectedItems || []).reduce((prev, item) => {
        const localTaxAmt = +item.price * +item.qty * 0.09;
        totalTaxAmount += localTaxAmt;
        return prev + +item.price * +item.qty * 1.09;
      }, 0),
    );
    setTaxAmount(totalTaxAmount);
  }, [selectedItems]);

  const [openItemChooser, setOpenItemChooser] = useState(false);

  const handleClickOpen = () => {
    setOpenItemChooser(true);
  };

  const handleClose = () => {
    setOpenItemChooser(false);
  };

  const handleQtyChange = (id, qty) => {
    const itemInList = selectedItems.find((item) => item._id === id);
    itemInList.qty = qty;
    setSelectedItems(selectedItems.slice());
  };

  const handleDeleteItem = (id) => {
    const index = selectedItems.findIndex((item) => item._id === id);
    if (index !== -1) {
      selectedItems.splice(index, 1);
      setSelectedItems(selectedItems.slice());
    }
  };

  // calculate line amount
  const calculateAmount = useCallback((row) => {
    return convertThisToCurrency((row.price * row.qty).toFixed(2));
  }, []);

  const findRowQty = useCallback(
    (itemId) => {
      return selectedItems.find((item) => itemId === item._id)?.qty || 1;
    },
    [selectedItems],
  );

  const addItemColumnConfig = [
    {
      name: "",
      width: "50px",
      button: true,
      cell: (row) => {
        return (
          !props.readOnly && (
            <IconButton
              onClick={(e) => {
                handleDeleteItem(row._id);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          )
        );
      },
    },
    {
      type: "input",
      name: "Qty.",
      width: "110px",
      center: true,
      cell: (row) => {
        return (
          <InputBase
            type={"number"}
            className={"input-group"}
            value={findRowQty(row._id)}
            onChange={(e) => handleQtyChange(row._id, e.target.value)}
            disabled={props.readOnly}
            startAdornment={
              !props.readOnly && (
                <IconButton>
                  <RemoveIcon
                    onClick={(e) =>
                      handleQtyChange(
                        row._id,
                        Math.max(0, findRowQty(row._id) - 1),
                      )
                    }
                  />
                </IconButton>
              )
            }
            endAdornment={
              !props.readOnly && (
                <IconButton>
                  <AddIcon
                    onClick={(e) =>
                      handleQtyChange(row._id, findRowQty(row._id) + 1)
                    }
                  />
                </IconButton>
              )
            }
          />
        );
      },
    },
    {
      name: "Code",
      width: "90px",
      selector: (row) => row.code,
    },
    {
      name: "Description",
      minWidth: "300px",
      wrap: true,
      cell: (row) => {
        return row.description;
      },
    },
    {
      name: "UOM",
      width: "100px",
      selector: (row) => row.uom,
    },
    {
      name: "Unit Price",
      width: "100px",
      right: true,
      cell: (row, index, column, id) => {
        return convertThisToCurrency(row.price.toFixed(2));
      },
    },
    {
      name: "Amount",
      width: "100px",
      right: true,
      cell: (row, index, column, id) => {
        return calculateAmount(row);
      },
    },
  ];

  return (
    <>
      <Grid container className={"form"}>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} className={"sub-action"}>
              <Typography variant={"h2"}>Items</Typography>
              {!props.readOnly && (
                <Button type={"button"} onClick={handleClickOpen}>
                  <AddIcon />
                </Button>
              )}
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4} className={"summary"}>
                  <Typography variant={"h3"}>
                    Estimated Total Amount:
                    <span>{convertThisToCurrency(totalPrice)}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={"summary"}>
                  <Typography variant={"h3"}>
                    Estimated Tax Amount:
                    <span>{convertThisToCurrency(taxAmount)}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={"table"}>
              <DataTable
                columns={addItemColumnConfig}
                data={selectedItems}
                persistTableHead={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        open={openItemChooser}
        maxWidth={false}
        onClose={handleClose}
      >
        <DialogTitle component={"div"}>
          <Grid item xs={12} className={"sub-action"}>
            <Button onClick={handleClose}>
              <ArrowBackIcon />
            </Button>
            <Typography variant={"h2"}>Find Item</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <SupplierItemPopup
            itemList={props.supplierItems}
            items={selectedItems}
            setItems={setSelectedItems}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
