import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Autocomplete,
  Dialog, DialogContent, DialogTitle,
} from "@mui/material";
import {
  KeyboardArrowDown as ArrowDownIcon,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import DataTable from "react-data-table-component";
import { fnbStoreEventItemTableColumnConfig } from "./data-table/fnb-store-event-item-table-column-config";
import useStyles from "./styles";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useUserState } from "../../context/UserContext";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FnbFoodPopup from "./components/ItemPopup/fnb-food-popup";
import DateTimeController from "../../components/DateTimeController";
const FnbStoreEventCard = (props) => {
  const classes = useStyles();
  const { userInfo } = useUserState();

  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  const { eventId } = useParams(); // url param => eventId when editing

  // handling form when adding / editing
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    control,
    setError,
    getValues,
    setValue,
  } = useForm({ mode: "all" });

  // un-assigned sub-con list
  const [message, setMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [currentEvent, setCurrentEvent] = useState(null); // when edit
  const [itemList, setItemList] = useState([]);
  
  const [duplicated, setDuplicated] = useState(false);
  const [enabledSwitchValue, setEnabledSwitchValue] = useState(true); // enable switch => default true
  const [errMessage, setErrMessage] = useState("");
  const [selectedStore, setSelectedStore] = useState(null);

  const [activeFrom, setActiveFrom] = useState(null);
  const [activeTo, setActiveTo] = useState(null);
  const [fnbStores, setFnbStores] = useState([]);
  const [openItemChooser, setOpenItemChooser] = useState(false);

  useEffect(() => {
    document.title = "New - F&B Store Event Card";
    loadPrerequisites();

    // redirect to default page if not authorized
    if (
      eventId &&
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.fnb_store_event,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        willClose: navigateToList,
      });
      setMessage("");
    }
  }, [message, responseData]);

  useEffect(() => {
    if (errMessage) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });

      setErrMessage("");
    }
  }, [errMessage]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  useEffect(() => {
    if (warningMessage) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: warningMessage,
      });
      setWarningMessage("");
    }
  }, [warningMessage, responseData]);

  // get current matrix in edit view
  useEffect(() => {
    if (!isLoading && !responseData && eventId) {
      sendRequest(`/v1/fnb-store-event/get-event-by-id/` + eventId, "GET");
    }
  }, [eventId]);

  // set form values when edit view
  useEffect(() => {
    if (eventId && responseData?.event) {
      const event = responseData?.event;
      document.title = `${event.name} - F&B Store Event Card`;
      setCurrentEvent(event);
      setValue("name", event.name || "");
      setValue("activeFrom", event.activeFrom || "");
      setValue("activeTo", event.activeTo || "");
      setValue("storeId", event.store.storeId || "");
      setValue("status", event.status);
      setEnabledSwitchValue(event.status);
      setActiveFrom(event.activeFrom);
      setActiveTo(event.activeTo);
      setItemList(event.items)
    }
  }, [responseData]);

  useEffect(() => {
    if (fnbStores && currentEvent) {
      setSelectedStore(
        fnbStores
          .filter((outlet) => outlet.storeId === currentEvent.store.storeId)
          .pop(),
      );
    }
  }, [currentEvent, fnbStores]);

  const loadPrerequisites = () => {
    sendRequest(`/v1/fnb-store-event/get-prerequisites`, "GET", {}).then(
      (response) => {
        let prerequisites = response.data.prerequisites;
        setFnbStores(prerequisites.fnbStores);
      },
    );
  };

  const navigateToList = () => {
    if (!eventId) window.location.href = "#/app/fnb-store-event/list";
  };

  const handleSubmitForm = async (data, e, duplicate = false) => {
    e.preventDefault();

    const event = {
      store: selectedStore,
      name: data.name.toUpperCase(),
      activeFrom: data.activeFrom == "" ? undefined : data.activeFrom,
      activeTo: data.activeTo == "" ? undefined : data.activeTo,
      status: data.status,
      items: itemList.map((i) => i.entityId),
    };

    let savedEvent = undefined,
      message = "";
    if (currentEvent && eventId) {
      // when edit
      event.entityId = currentEvent.entityId;
      const result = await sendRequest(
        `/v1/fnb-store-event/${eventId}`,
        "PUT",
        event,
      );
      if (result?.status === 200 && result?.data?.event) {
        message = `F&B store event ${result.data.event.name} has been updated.`;
        savedEvent = result?.data?.event;
      }
    } else {
      const result = await sendRequest(`/v1/fnb-store-event`, "POST", event);
      if (result?.status === 201 && result?.data?.event) {
        message = `New F&B store event ${result.data.event.name} has been created.`;
        savedEvent = result?.data?.event;
      }
    }

    if (duplicate && savedEvent) {
      duplicateStore();
    } else {
      setMessage(message);
    }
  };

  // set resource table columns
  const columns = fnbStoreEventItemTableColumnConfig(
    onRemoveRow,
    currentEvent &&
      eventId &&
      !auth.checkModulePrivilege(
        PrivilegeModules.fnb_store_event,
        PrivilegeActions.edit,
      ),
  );

  // remove resource from list
  function onRemoveRow(i) {
    const rows = [...itemList];
    rows.splice(i, 1);
    setItemList([...rows]);
  }

  // reset prev matrix data & copy resources to new matrix
  function duplicateStore() {
    props.history.push("/app/fnb-store-event/card");
    setDuplicated(true);
    setCurrentEvent(null);
    setSelectedStore(null);
    setValue("name", "");
    setValue("activeFrom", null);
    setValue("activeTo", null);
    setEnabledSwitchValue(true);
  }
 
  const onChangeStore = (newValue) => {
    setValue("storeId", newValue.storeId);
    setItemList([]);
    setSelectedStore(newValue);
  };

  const onChangeDate_ActiveFrom = (date) => {
    setValue("activeFrom", date);
    setActiveFrom(date);
  };

  const onChangeDate_ActiveTo = (date) => {
    setValue("activeTo", date);
    setActiveTo(date);
  };

  const addNewItems = () => {
    setOpenItemChooser(true);
  };

  const closeAddNewItem = () => {
    setOpenItemChooser(false);
  }

  return (
    <>
      {isLoading && (
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      {
        <form>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant={"h1"}>
                F&B Store Event Card
                {currentEvent ? ` - ${currentEvent.name}` : ` - New`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
              <Button
                onClick={() => props.history.push("/app/fnb-store-event/list")}
              >
                Cancel
              </Button>
              {((!currentEvent &&
                !eventId &&
                auth.checkModulePrivilege(
                  PrivilegeModules.fnb_store_event,
                  PrivilegeActions.add,
                )) ||
                (currentEvent &&
                  eventId &&
                  auth.checkModulePrivilege(
                    PrivilegeModules.fnb_store_event,
                    PrivilegeActions.edit,
                  ))) && (
                <Button
                  className={"primary"}
                  type={"button"}
                  onClick={handleSubmit(handleSubmitForm)}
                >
                  Save
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <Typography variant={"h2"}>General</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Autocomplete
                            options={fnbStores}
                            getOptionLabel={(store) => `${store.name}-${store.storeCode}`}
                            value={selectedStore}
                            onChange={(e, newValue) => onChangeStore(newValue)}
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Store"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                            disabled={currentEvent ? true : false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <TextField
                            id={"name"}
                            label={"Name"}
                            variant={"outlined"}
                            {...register("name", {
                              required: {
                                value: true,
                                message: "Name cannot be blank.",
                              },
                            })}
                            autoComplete={"off"}
                            error={!!formErrors?.name}
                            helperText={formErrors?.name?.message}
                            onChange={(event) => validateText(event)}
                            disabled={
                              currentEvent &&
                              eventId &&
                              !auth.checkModulePrivilege(
                                PrivilegeModules.fnb_store_event,
                                PrivilegeActions.edit,
                              )
                                ? true
                                : false
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <DateTimeController
                            control={control}
                            label={'Active From'}
                            name={'activeFrom'}
                            format={"dd/MM/yyyy HH:mm"}
                            required={true}
                            value={activeFrom}
                            onChange={onChangeDate_ActiveFrom}
                            disabled={
                              currentEvent &&
                                eventId &&
                                !auth.checkModulePrivilege(
                                  PrivilegeModules.fnb_store_event,
                                  PrivilegeActions.edit,
                                )
                                  ? true
                                  : false
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <DateTimeController
                            control={control}
                            label={'Active To'}
                            name={'activeTo'}
                            format={"dd/MM/yyyy HH:mm"}
                            required={true}
                            value={activeTo}
                            onChange={onChangeDate_ActiveTo}
                            disabled={
                              currentEvent &&
                                eventId &&
                                !auth.checkModulePrivilege(
                                  PrivilegeModules.fnb_store_event,
                                  PrivilegeActions.edit,
                                )
                                  ? true
                                  : false
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                          <FormControlLabel
                            variant={"subtitle"}
                            labelPlacement="start"
                            control={
                              <Switch
                                {...register("status")}
                                checked={enabledSwitchValue}
                                onChange={(e) =>
                                  setEnabledSwitchValue(e.target.checked)
                                }
                              />
                            }
                            label="Enable"
                            disabled={
                              currentEvent &&
                              eventId &&
                              !auth.checkModulePrivilege(
                                PrivilegeModules.fnb_store_event,
                                PrivilegeActions.edit,
                              )
                                ? true
                                : false
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container className={"form"}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Items</Typography>
                    {(auth.checkModulePrivilege(
                      PrivilegeModules.fnb_store_event,
                      PrivilegeActions.add,
                    ) ||
                      auth.checkModulePrivilege(
                        PrivilegeModules.fnb_store_event,
                        PrivilegeActions.edit,
                      )) && !!selectedStore && (
                      <Button 
                        type={"button"} 
                        onClick={addNewItems}
                      >
                        <AddIcon />
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} className={"table"}>
                    <DataTable
                      fixedHeader={true}
                      persistTableHead={true}
                      columns={columns}
                      data={itemList}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      }

      <Dialog
        fullWidth={true}
        open={openItemChooser}
        maxWidth={false}
        onClose={closeAddNewItem}
      >
        <DialogTitle component={"div"}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={"sub-action"}>
            <Button onClick={() => closeAddNewItem()}>
              <ArrowBackIcon />
            </Button>
            <Typography variant={"h2"}>Find Item</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <FnbFoodPopup selectedItemList={itemList} setSelectedItemList={setItemList} selectedStore={selectedStore} itemPickerMode={true} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withRouter(FnbStoreEventCard);
